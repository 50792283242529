<script setup>
import {defineProps, ref, watch} from 'vue'

const props = defineProps({
	front:{
		type: Boolean,
		required: true
	}
})

const isFront = ref(props.front)

watch(
	() => props.front,
	(newValue) => {
		isFront.value = newValue;
		if(isFront.value) {
			console.log('page 4 front')
			handleShowAnimation()
		}
	}
)

const backgroundVisible = ref(false)
const partVisible = ref([false, false, false,false,false,false,false])

function handleShowAnimation() {
	console.log('show animation')
	setTimeout(() => {
		backgroundVisible.value = true;
		console.log('backgroundVisible: true');
		setTimeout(() => {
			partVisible.value[0] = true;
			console.log('partVisible[0]: true');
			setTimeout(() => {
				partVisible.value[1] = true;
				console.log('partVisible[1]: true');
				setTimeout(() => {
					partVisible.value[2] = true;
					console.log('partVisible[2]: true');
					setTimeout(() => {
						partVisible.value[3] = true;
						console.log('partVisible[3]: true');
						setTimeout(() => {
							partVisible.value[4] = true;
							console.log('partVisible[4]: true');
							setTimeout(() => {
								partVisible.value[5] = true;
								console.log('partVisible[5]: true');
							}, 2000);
						}, 1000);
					}, 1000);
				}, 1000);
			}, 1200);
		}, 1500);
	}, 1500);
}


</script>

<template>
	<div class="container" :class="{active: backgroundVisible}">
		<div class="inner-container">
			<div class="part big-title animated" v-show="partVisible[0]">
				<span class="text">只願君心似我心</span>
				<br>
				<span class="text">定不負相思意</span>
			</div>
			<div class="part animated" v-show="partVisible[1]">
				<span class="text">一週年很短 陪伴很長</span>
			</div>
			<div class="part animated" v-show="partVisible[2]">
				<span class="text">就讓這小小的網站</span>
			</div>
			<div class="part animated" v-show="partVisible[3]">
				<span class="text">在互聯網的一隅</span>
			</div>
			<div class="part animated" v-show="partVisible[4]">
				<span class="text">默默見證我們愛的物語</span>
			</div>
			<div class="part end-text animated" v-show="partVisible[5]">
				<span class="text">致  最愛的 Leslie</span>
			</div>
		</div>
	</div>
</template>

<style scoped>
.container{
	height: 100%;
	max-height: 100%;
	width: 100%;
	/*background-color: #42b983;*/
	display: flex;
	background: radial-gradient(ellipse 160% 160% at 100% 100%, rgb(255, 197, 205) 0%, rgb(255, 245, 245) 65%);
	opacity: 0; /* 初始透明度 */
	transition: opacity 1.5s ease /* 3秒的透明度过渡 */
}

.container.active{
	opacity: 1;
}

.inner-container{
	margin: 30px;
	/*background-color: pink;*/
	width: 100%;
	display: flex;
	flex-direction: column;
}

.animated{
	animation: fadeIn 2s forwards 0s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.part{

}

.big-title{
	font-size: 2em;
	padding-bottom: 20px;
}

.text{
	font-family: "Hina Mincho", serif;
}

.end-text{
	padding-top: 20px;
	font-weight: bold;
}

@media screen and (min-width: 800px){

}
</style>