<script setup>
import {defineProps, onMounted, onUnmounted, ref, watch} from 'vue'

const props = defineProps({
	front:{
		type: Boolean,
		required: true
	}
})

const isFront = ref(props.front)
const formattedTime = ref('')
const formattedDate = ref('')

const partVisible = ref([false, false, false, false, false])//控制動畫

const updateTime = () => {
	const oneDay = 24 * 60 * 60 * 1000;
	const now = new Date();
	const start_of_love = new Date(2023, 9, 15, 6, 36,0, 0 );
	const diffInTime = Math.abs(now - start_of_love);
	const remainingTime = diffInTime % oneDay;
	const hours = Math.floor(remainingTime / (60 * 60 * 1000));
	const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
	const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);
	formattedTime.value = `${hours}時${minutes}分${seconds}秒`;
};

const updateDate = () => {
	const oneDay = 24 * 60 * 60 * 1000;
	const now = new Date();
	const start_of_love = new Date(2023, 9, 15, 6, 36,0, 0 );
	const diffInTime = Math.abs(now - start_of_love);
	if(diffInTime < 0) {
		formattedDate.value = '還沒開始';
	}else {
		formattedDate.value = `${Math.floor(diffInTime / oneDay)} 天`;
	}
};

let intervalIdTime;
let intervalIdDate;
onMounted(() => {
	updateTime(); // 初始化時先調用一次
	intervalIdTime = setInterval(updateTime, 1000); // 每秒更新
	updateDate();
	intervalIdDate = setInterval(updateDate, 10000); // 每十秒更新
});

onUnmounted(() => {
	clearInterval(intervalIdTime);
	clearInterval(intervalIdDate);
});

watch(
	() => props.front,
	(newValue) => {
		isFront.value = newValue;
		if(isFront.value) {
			console.log('page 1 front')
			handleShowAnimation()
		}
	}
)

function handleShowAnimation() {//有時間改成遞歸實現
	console.log('show animation')
	setTimeout(() => {
		partVisible.value[0] = true;
		console.log('partVisible[0]: true');
		setTimeout(() => {
			partVisible.value[1] = true;
			console.log('partVisible[1]: true');
			setTimeout(() => {
				partVisible.value[2] = true;
				console.log('partVisible[2]: true');
				setTimeout(() => {
					partVisible.value[3] = true;
					console.log('partVisible[3]: true');
					setTimeout(() => {
						partVisible.value[4] = true;
						console.log('partVisible[4]: true');
					}, 1000);
				}, 1000);
			}, 1200);
		}, 1500);
	}, 1500);
}
</script>

<template>
<div class="container">
	<div class="inner-container">
		<div class="love-clock">
			<img class="heart-image animated" src="../../assets/cherry_blossom.png" alt="cherry_blossom" v-show="partVisible[0]"/>
			<div class="clock animated" v-show="partVisible[1]">
				<span class="para">我們已經相愛了</span>
				<div class="para">
					<span class="text">{{}}</span>
					<span class="love-time title">
						{{ formattedDate }}
					</span>
					<br>
					<span class="love-time title">
						{{ formattedTime }}
					</span>
				</div>
			</div>
		</div>
		<div class="part optional animated" v-show="partVisible[2]">
			<span class="title">2023年4月1日</span>
			<span class="para">相識</span>
		</div>
		<div class="part animated" v-show="partVisible[3]">
			<span class="title">2023年10月15日</span>
			<span class="para">相戀</span>
		</div>
		<div class="part  optional animated" v-show="partVisible[4]">
			<span class="title">2024年10月15日</span>
			<span class="para">一週年紀念</span>
		</div>
	</div>
</div>
</template>

<style scoped>
.container{
	height: 100%;
	max-height: 100%;
	width: 100%;
	/*background-color: #42b983;*/
	display: flex;
}
.inner-container{
	margin: 30px;
	margin-bottom: 60px;
	/*background-color: pink;*/
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.animated{
	animation: fadeIn 2s forwards 0s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.part {
	display: flex;
	align-items: center;
	justify-content: center;
	animation: fadeIn 2s forwards 0s;
	flex-direction: column;
	margin: 20px;
}

.text{
	font-family: "Hina Mincho", serif;
	font-size: 24px;
}

.title{
	font-family: "Hina Mincho", serif;
	font-size: 24px;
}

.para{
	font-family: "Hina Mincho", serif;
	font-size: 16px;

}

.love-clock{
	position: relative;
	max-width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	transition: all 0.5s ease-in-out;
}

img{
	max-width: 100%;
}

.heart-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 1;
}

.clock{
	position: absolute;
	top: 30%;
	width: 100%;
	height: 100%;
	z-index: 2;
	text-align: center;
}



@media screen and (max-height: 727px){
	.part{
		margin: 5px;
	}
	.love-clock{
		max-width: 94%;
	}
	.title{
		font-size: 22px;
	}
	.optional{
		display: none;

	}
}

@media screen and (max-height: 520px){
	.part{
		display: none;
	}
}

@media screen and (min-width: 800px){

}
</style>