<script setup>
import {onMounted, ref} from "vue";

const logoScale = ref(1);
const headerHeight = ref(0);

function scaleContainer() {
	const container = document.querySelector('.logo-container');
	const start = document.querySelector('.start');
	const {innerWidth: winWidth, innerHeight: winHeight} = window;
	const scaleX = winWidth * (80.58693*Math.pow(winWidth,-0.7657721)) / container.offsetWidth;
	const scaleY = winHeight * 0.4 / container.offsetHeight;
	const scale = Math.min(scaleX, scaleY);
	logoScale.value = scale;
	container.style.transform = `scale(${scale})`;
	start.style.transform = `scale(${scale})`;
}

function getDisplacement(){
	const container = document.querySelector('.logo-container');
	const absoluteTop = container.getBoundingClientRect().top;
	const absoluteBottom = container.getBoundingClientRect().bottom;
	const winHeight = window.innerHeight;
	document.documentElement.style.setProperty('--height', `${winHeight}px`);
	document.documentElement.style.setProperty('--logo-height', `${(absoluteBottom-absoluteTop)}px`);
	document.documentElement.style.setProperty('--logo-displacement', `${(winHeight-absoluteBottom+(0.5*(1-1/logoScale.value)*(absoluteBottom-absoluteTop)))}px`);
	document.documentElement.style.setProperty('--page-displacement', `${(headerHeight.value-winHeight)}px`);
}

function setViewportHeight() {
	document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
}

function setHeaderHeight() {
	const element = document.querySelector('.head-bar');
	const height = element.offsetHeight;
	headerHeight.value = height;
	document.documentElement.style.setProperty('--header-height', `${height}px`);
}


window.addEventListener('resize', scaleContainer);
window.addEventListener('load', scaleContainer);
window.addEventListener('resize', setViewportHeight);
window.addEventListener('load', setViewportHeight);
window.addEventListener('resize', setHeaderHeight);
window.addEventListener('load', setHeaderHeight);
window.addEventListener('resize', getDisplacement);
window.addEventListener('load', getDisplacement);

const logoVisible = ref(true)
const startButtonVisible = ref(true)

function handleClicked() {
	logoVisible.value = false;
	startButtonVisible.value = false;
}

onMounted(() => {
	logoVisible.value = true;
	startButtonVisible.value = true;
})
</script>

<template>
	<transition name="swipe">
		<div class="start-page-container" @click="handleClicked">
			<transition name="locate">
				<div class="logo-container" v-show="logoVisible">
					<div class="english-part">
						<span class="upper-text">LeSLie   &   XJY</span>
						<span class="lower-text">Love Anniversary</span>
					</div>
					<div class="divider"></div>
					<div class="chinese-part">
						一週年紀念
					</div>
				</div>
			</transition>
			<div :class="{ hidden: !startButtonVisible }" class="start">
				「開啓」
			</div>
		</div>
	</transition>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Hina+Mincho&display=swap");

.start-page-container {
	background-color: rgba(255, 182, 193, 1);
	width: 100%;
	min-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.logo-container {
	width: 240px;
	height: var(--header-height);
	display: flex;
	justify-content: center;
	align-items: center;
}

.english-part {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.upper-text {
	font-size: 12px;
	font-family: "Josefin Slab", serif;
	font-optical-sizing: auto;
	font-weight: 600;
	font-style: normal;
}

.lower-text {
	font-size: 22px;
	font-family: "Cookie", cursive;
	font-weight: 400;
	font-style: normal;
	line-height: 22px;
}

.divider {
	margin-left: 10px;
	margin-right: 10px;
	width: 1px;
	height: 20px;
	background-color: #373737;
}

.chinese-part {
	font-size: 20px;
	font-family: "Hina Mincho", serif;
	font-weight: 200;
	font-style: normal;
}

.start {
	padding: 30px;
	font-family: "Hina Mincho", serif;
	font-size: 18px;
	position: absolute;
	bottom: 10%;
	opacity: 1;
	transition: opacity 0.5s;
}

.hidden {
	opacity: 0;
}

@media screen and (max-height: 200px) {
	.start {
		display: none;
	}
}

.swipe-leave-from, .swipe-leave-to {
	animation: swipe-out 2.5s ease-in-out;
}

@keyframes swipe-out {
	20% {
		transform: translateY(0);
		border-radius: 0;
	}
	95%,100% {
		transform: translateY(var(--page-displacement));
		border-radius: 10px;
	}
}

.locate-leave-active {
	animation: locateOut 2.5s forwards ease-in-out;
}

@keyframes locateOut {
	0% {
	}
	95%,100% {
		transform: scale(1) translateY(var(--logo-displacement));
	}
}

</style>