<template>
	<!--  <img alt="Vue logo" src="./assets/logo.png">-->
	<MainPage></MainPage>
</template>

<script>

import MainPage from "@/components/MainPage.vue";
import ContinueSwipe from "@/components/utils/ContinueSwipe.vue";

export default {
	name: 'App',
	components: {
		MainPage,
		// eslint-disable-next-line vue/no-unused-components
		ContinueSwipe
	}
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	width: 100%;
}
</style>
