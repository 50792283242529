<script setup>

import {onMounted, defineProps} from "vue";

defineProps({
	isVisible:{
		type: Boolean,
		required: true
	},
	continueString:{
		type: String,
		required: true
	},
	hasNext:{
		type: Boolean,
		required: true
	}
})

onMounted(() => {

})

</script>

<template>
	<transition name="fade">
		<div class="scroll-hint" v-if="isVisible">
			<div class="arrow" v-if="hasNext">▼</div>
			<div class="text">
			<span v-for="(char, index) in continueString" :key="index" class="char">
                {{ char }}
            </span>
			</div>
			<div class="arrow" v-if="hasNext">▼</div>
		</div>
	</transition>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Hina+Mincho&display=swap");

.fade-enter-active{
	animation: 3.2s fadeIn 0s forwards;
}

.fade-leave-active {
	animation: fadeOut 1.7s forwards;
}

.scroll-hint {
	text-align: center;
	color: black;
	display: flex;
	flex-direction: row;
}

.text {
	margin-top: 10px;
	font-family: "Hina Mincho", serif;
	font-weight: 400;
	font-style: normal;
}

.char {
	animation: fadeInScale 4s infinite 1s;
	display: inline-block;
	opacity: 0;
	padding: 2px;
}

/* 字体从小变大并透明度逐渐减小的动画 */
@keyframes fadeInScale {
	0% {
		opacity: 0;
		transform: scale(0.3);
	}
	50%, 70% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
	}
}

.arrow {
	font-size: 18px;
	max-height: 20px;
	line-height: 20px;
	padding-left: 10px;
	padding-right: 10px;
	animation: bounce 2s infinite;
}

/* 向下弹跳的效果 */
@keyframes bounce {
	0%, 100% {
		transform: translateY(12px);
	}
	45%, 55% {
		transform: translateY(18px);
	}
}

@keyframes fadeIn {
	0%, 50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	50%, 100% {
		opacity: 0;
	}
}
</style>