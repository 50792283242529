<script setup>
import {defineProps, ref, watch} from 'vue'

const props = defineProps({
	front: {
		type: Boolean,
		required: true
	}
})

const isFront = ref(props.front)

watch(
	() => props.front,
	(newValue) => {
		isFront.value = newValue;
		if (isFront.value) {
			console.log('page 2 front')
			handleShowAnimation()
		}
	}
)

const backgroundVisible = ref(false)
const partVisible = ref([false, false, false])

function handleShowAnimation() {
	console.log('show animation')
	setTimeout(() => {
		backgroundVisible.value = true;
		console.log('backgroundVisible: true');
		setTimeout(() => {
			partVisible.value[0] = true;
			console.log('partVisible[0]: true');
			setTimeout(() => {
				partVisible.value[1] = true;
				console.log('partVisible[1]: true');
				setTimeout(() => {
					partVisible.value[2] = true;
					console.log('partVisible[2]: true');
				}, 1000);
			}, 1200);
		}, 1500);
	}, 1500);
}

const memoryVisible1 = ref(true)
const memoryVisible2 = ref(false)

function handleShowMemory() {
	console.log('show memory')
	memoryVisible1.value = false;
	setTimeout(() => {
		memoryVisible2.value = true;
	}, 1700);
}

</script>

<template>
	<div class="container" :class="{active: backgroundVisible}">
		<div class="inner-container">
			<div class="part image-padding" v-show="partVisible[0]">
				<div class="image-container">
					<img src="../../assets/IMG_6254.jpeg" alt="迪士尼"/>
				</div>
			</div>
			<div class="part" v-show="partVisible[1]">
				<transition name="content-fade">
					<div class="text outer-block" v-show="memoryVisible1">
						<div class="text block" style="padding-top: 5px; padding-bottom: 5px">
						<span>
						我們的故事
						</span>
							<span>
						被許多的「<span style="font-weight: bolder">第一次</span>」
						</span>
							<span>
						標上註脚
						</span>
						</div>
						<div class="text block" style="padding-top: 5px; padding-bottom: 5px">
						<span>
							第一次夜爬
						</span>
							<span>
							第一次露營觀星
						</span>
							<span>
							第一次去迪士尼
						</span>
							<span>
							第一次吃到寶寶做的小餅乾
						</span>
							<span>
							第一次去看鄧紫棋的演唱會
						</span>
							<span>
							永遠相伴左右
						</span>
						</div>
					</div>
				</transition>
				<transition name="content-fade">
					<div class="text outer-block" v-show="memoryVisible2">
						<div class="text block" style="padding-top: 5px; padding-bottom: 5px">
						<span>
						我們還一起看過
						</span>
						<span>
						「<span style="font-weight: bolder">三</span>」場話劇
						</span>
						<span>
						「<span style="font-weight: bolder">五</span>」場電影
						</span>
						</div>
						<div class="text block" style="padding-top: 5px; padding-bottom: 5px">
						<span>
							一起玩過
						</span>
							<span>
							「<span style="font-weight: bold">森林冰火人</span>」
						</span>
							<span>
							「<span style="font-weight: bold">雙人成行</span>」
						</span>
							<span>
							「<span style="font-weight: bold">胡鬧廚房</span>」
						</span>
							<span>
							「<span style="font-weight: bold">銹湖</span>」系列
						</span>
							<span>
							還有「<span style="font-weight: bold">原神</span>」👉👈
						</span>
						</div>
					</div>
				</transition>
			</div>
			<div class="part" v-show="partVisible[2]" @click="handleShowMemory">
				<transition name="content-fade">
					<span class="button" v-if="memoryVisible1">更多回憶</span>
				</transition>
			</div>
		</div>
	</div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Yuji+Boku&display=swap');

.container {
	height: 100%;
	max-height: 100%;
	width: 100%;
	background: radial-gradient(ellipse 90% 65% at 50% 45%, #b1e1ff 0%, rgba(255, 255, 255, 1) 65%);
	display: flex;
	opacity: 0; /* 初始透明度 */
	transition: opacity 1.5s ease /* 3秒的透明度过渡 */
}

.container.active {
	opacity: 1;
}

.inner-container {
	margin: 30px;
	/*background-color: pink;*/
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.part {
	display: flex;
	align-items: center;
	justify-content: center;
	animation: fadeIn 2s forwards 0s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.content-fade-enter-active, .content-fade-leave-active {
	transition: opacity 1.7s ease-in-out;
}

.content-fade-enter-from, .content-fade-leave-to {
	opacity: 0;
}

.text {
	font-family: "Hina Mincho", serif;
	font-size: 16px;
}

.outer-block {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.block {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.image-container img {
	width: 100%;
	height: 50%;
	object-fit: cover;
	border-radius: 50%;
	border: 2px solid lightskyblue;
}

.image-padding {
	padding: 10% 12%;
}

.button {
	padding: 5px 15px;
	margin: 20px 10px 10px;
	border: 2px solid #97d3fa;
	background-color: lightskyblue;
	font-family: "Yuji Boku", serif;
	font-weight: 400;
	font-style: normal;
	color: white;
	border-radius: 20px;
	cursor: pointer;
	font-size: 18px;
}

@media screen and (max-height: 600px) {
	.outer-block {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.block {
		padding-left: 0;
		padding-right: 0;
		font-size: 12px;
	}
}

@media screen and (max-height: 750px) {
	.text {
		font-size: 14px;
	}

	.inner-container {
		margin: 10px;
	}

	.image-padding {
		padding: 7% 25% 5%;
	}

	.button {
		padding: 3px 10px;
		margin: 0 5px 5px;
		border-radius: 15px;
		font-size: 14px;
	}
}

@media screen and (min-width: 800px) {

}
</style>