<script setup>

</script>

<template>
<div class="container">
	<div class="english-part">
		<span class="upper-text">LeSLie   &   XJY</span>
		<span class="lower-text">Love Anniversary</span>
	</div>
	<div class="divider"></div>
	<div class="chinese-part">
		一週年紀念
	</div>
</div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cookie&family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Hina+Mincho&display=swap");

.container{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
.english-part{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.upper-text{
	font-size: 12px;
	font-family: "Josefin Slab", serif;
	font-optical-sizing: auto;
	font-weight: 600;
	font-style: normal;
}

.lower-text{
	font-size: 22px;
	font-family: "Cookie", cursive;
	font-weight: 400;
	font-style: normal;
	line-height: 22px;
}

.divider{
	margin-left: 10px;
	margin-right: 10px;
	width: 1px;
	height: 50%;
	background-color: #373737;
}

.chinese-part{
	font-size: 20px;
	font-family: "Hina Mincho", serif;
	font-weight: 200;
	font-style: normal;
}
</style>